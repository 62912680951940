import * as React from "react";
import { FC } from "react";
import { useKeenSlider } from 'keen-slider/react';
import projects from "../../data/projects.json";
import ProjectItemSummary from "./ProjectItemSummary";
import { TypeImage } from "../../types";

interface IFeaturedProjects {
    projectFilter: string
    projectImages: TypeImage[]
    withNav?: boolean
}

const FeaturedProjects: FC<IFeaturedProjects> = ( { projectFilter, projectImages, withNav } ) => {
    const [ sliderRef, slider ] = useKeenSlider<HTMLDivElement>( {
        slidesPerView: 3,
        spacing      : 32,
        breakpoints  : {
            '(min-width: 0px) and (max-width: 720px)'  : {
                slidesPerView: 1,
            },
            '(min-width: 720px) and (max-width: 992px)': {
                slidesPerView: 2,
            },
        }
    } )
    return (
        <>
            <div ref={ sliderRef } className={ "keen-slider projects-summary" }>
                { projects.filter( (projects => projects.doc.categories === projectFilter) ).filter( project => project.doc.featured ).map( project => (
                    <ProjectItemSummary key={ project.id } className={ "keen-slider__slide" } images={ projectImages } project={ project } disableAnimation/>
                ) ) }
            </div>
            { withNav &&
            <nav className={ "flex--center jf__cnt--center mt--xxl" }>
                <button onClick={ () => slider.prev() } className={ "cursor--pointer mr--sm" }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height={ 32 } className={ "fill--textDimmed" }>
                        <path d="M12.452 4.516c.446.436.481 1.043 0 1.576L8.705 10l3.747 3.908c.481.533.446 1.141 0 1.574-.445.436-1.197.408-1.615 0-.418-.406-4.502-4.695-4.502-4.695a1.095 1.095 0 0 1 0-1.576s4.084-4.287 4.502-4.695c.418-.409 1.17-.436 1.615 0z"/>
                    </svg>
                </button>
                <button onClick={ () => slider.next() } className={ "cursor--pointer ml--sm" }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height={ 32 } className={ "fill--textDimmed" }>
                        <path d="M9.163 4.516c.418.408 4.502 4.695 4.502 4.695a1.095 1.095 0 0 1 0 1.576s-4.084 4.289-4.502 4.695c-.418.408-1.17.436-1.615 0-.446-.434-.481-1.041 0-1.574L11.295 10 7.548 6.092c-.481-.533-.446-1.141 0-1.576.445-.436 1.197-.409 1.615 0z"/>
                    </svg>
                </button>
            </nav>
            }
        </>
    )
}

export default FeaturedProjects
