import * as React from "react";
import { FC, useRef, useState } from "react";
import { graphql } from "gatsby";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { GatsbyImage } from "gatsby-plugin-image";
import { Translatable, TypeImage } from "../../types";
import Layout from "../layout/Layout";
import HeadMeta from "../layout/HeadMeta";
import { motion } from "framer-motion";
import { fadeLeft, hVariants, pVariants } from "../_animationVariants";
import { imgAlt } from "../../utilities";
import { makeButtonClass } from "../cssClasses";
import FeaturedProjects from "../project/FeaturedProjects";
import t from "../../i18n";
import ContactForm from "../contact/ContactForm";
import LocalizedAnchorLink from "../ui/LocalizedAnchorLink";
import services from "../../data/services.json"
import { Option } from "../ui/Forms";

type Service = {
    icon: string
    title: Translatable
    description: Translatable
}

interface IServiceLandingPage {
    data: {
        image: TypeImage
        projectImages: { nodes: TypeImage[] }
    }
    pageContext: {
        locale: "en" | "el"
        slug: string
        contactOptions: Option[]
        title: Translatable
        description: Translatable
        services: Service[]
    }
}

const ServiceLandingPage: FC<IServiceLandingPage> = ( { data, pageContext } ) => {
    const l = pageContext.locale
    const service = services.filter( ( ser ) => ser.slug === pageContext.slug )[0];
    const [option, setOption] = useState(0);
    const contactRef = useRef<HTMLDivElement>(null)
    const executeScroll = () => contactRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    return (
        <Layout>
            <HeadMeta title={ service.title[l] } description={ service.description[l] }/>

            <section className={ "flex--center overflow--hidden h--v100 relative decoration-x bg--bg" }>
                <div className={ "lg:col--7 flex--center-middle px--xl py--header" }>
                    <div>
                        <motion.h1 variants={ hVariants } className={ "text--xl-1" } style={ { whiteSpace: "pre-wrap" } }>{ service.title[l] }</motion.h1>
                        <motion.p variants={ pVariants } className={ "max-w--60ch my--lg" }>{ service.description[l] }</motion.p>
                        <motion.div variants={ hVariants } className={ "inline-flex" }>
                            <LocalizedAnchorLink className={ makeButtonClass( "accent" ).fill } to={ `services` }>{ t( "Learn more" ) }</LocalizedAnchorLink>
                        </motion.div>
                    </div>
                </div>
                <motion.div variants={ fadeLeft } className={ "col--5 none lg:block relative" }>
                    <GatsbyImage
                        className={ "h--v100" }
                        image={ data.image.childImageSharp.gatsbyImageData }
                        imgStyle={ { objectFit: "cover" } }
                        alt={ imgAlt( data.image.relativePath ) }
                    />
                </motion.div>
            </section>

            <section id={ "services" } className={ "py--huge px--xl" }>
                <h2 className={ "mb--xxl text--xl-1 text--center" }>{ t( "Services" ) }</h2>
                <div className={ "container--lg lg:flex" }>
                    { service.pricing.map( (item, i) => {
                        return (
                            <div key={ item.title } className={ "lg:col--4 px--md" }>
                                <div className="bg--bgDimmed mb--md lg:mb--0 round--md h--100 flex flex--column elevation--1">
                                    <div className={ "text--center p--xxl" }>
                                        <h3 className={ "h2" }>{ item.title }</h3>
                                        <p className={ "text--textDimmed" }>{ item.description[l] }</p>
                                        <button className={ makeButtonClass( "accent", "mt--md" ).fill }
                                                onClick={() => {
                                                    setOption(i);
                                                    executeScroll();
                                                }}>
                                            { t( "I want this" ) }
                                        </button>
                                    </div>

                                    <ul className={ "px--xxl mt--0 py--xl text--center bg--bg flex--grow round-b--md" }>
                                        { item.includes[l].map( inc =>
                                            <li key={ inc } className={ "h5 flex--center-middle p--sm" }>
                                                { inc }
                                            </li>
                                        ) }
                                    </ul>
                                </div>
                            </div>
                        )
                    } ) }
                </div>
            </section>

            <section id={ "projects" } className={ "py--huge px--xl bg--bgDimmed" }>
                <div className={ "container--lg text--center" }>
                    <h2 className={ "mb--xxl text--xl-1 text--center" }>{ t( "Featured Projects" ) }</h2>
                    <FeaturedProjects projectImages={ data.projectImages.nodes } projectFilter={ pageContext.slug }/>
                    <Link to={ `/projects` } className={ makeButtonClass( "accent", "mt--xxl" ).fill }>{ t( "All Projects" ) }</Link>
                </div>
            </section>



            <section id={ "contact" } className={ "py--huge px--xl" } ref={contactRef}>
                <div className={ "w--100 max-w--700 mx--auto" }>
                    <h2 className={ "text--xl-1 pb--xl" }>{ t( "Contact" ) }</h2>
                    <ContactForm withService servicesOptions={pageContext.contactOptions} selectedService={pageContext.contactOptions[option].value}/>
                </div>
            </section>
        </Layout>
    )
}

export default ServiceLandingPage

export const query = graphql`
    query ($image: String!) {
        image: file(relativePath: {eq: $image}) {
            relativePath
            childImageSharp {
                original {
                    src
                }
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        projectImages: allFile(filter: {extension: {regex: "/jpeg|jpg|png|gif/"}}) {
            nodes {
                relativePath
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        height: 600
                        quality: 80
                        placeholder: BLURRED
                        layout: CONSTRAINED
                    )
                }
            }
        }
    }
`
